/* eslint-disable react/jsx-key */
import { Datagrid, DateField, Filter, List, ShowButton, TextField, TextInput, usePermissions } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { DeviceListActions } from './ui/actions';
import { Permissions, Roles } from '../../../../types';
import { hasPermission } from '../../../validators';

const DevicesFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn />
      <TextInput source="name" alwaysOn />
      <TextInput source="mac" alwaysOn />
    </Filter>
  );
};

export const DeviceList = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  return (
    <List
      actions={<DeviceListActions isCreateDisabled={!hasPermission(role, Permissions.CreateDevices)} />}
      filters={<DevicesFilters />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.deviceId} sortable={false} />
        <TextField source="name" label={LABELS.name} sortable={false} />
        <TextField source="alias" label={LABELS.alias} sortable={false} />
        <TextField source="user.id" label={LABELS.userId} sortable={false} />
        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
